/**
 * @license
 * Copyright Qevo - Queue Evolution. All Rights Reserved.
 */
/**
 * @class
 * @description
 * Qore Console entry point
 * Created by Carlos.Moreira @ 2018/01/25
 */

// Angular Components
import { Component, Renderer2, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';

// Third Party Services
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { LangChangeEvent } from '@ngx-translate/core';

// Shared Components
import { QoreConfig } from './shared/qore.config';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';
import { AuthState, Language, Profile } from 'qevo.models';
import { LoggerService, AuthService } from 'qevo.services';

@Component({
	selector: 'qore',
	template: '<router-outlet></router-outlet>',
	styles: ['']
})
export class QoreComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/

	// Component
	protected componentName: string;

	// Subscriptions
	private _userSubscription: Subscription;
	private _translateSubscription: Subscription;

	// Observable of Authentication State
	private _authState$: Observable<AuthState>;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		private _translateService: TranslateService,
		private _cookieService: CookieService,
		private _qoreConfig: QoreConfig,
		private _authService: AuthService,
		private _elRef: ElementRef,
		private _renderer: Renderer2,
		private _logger: LoggerService,
		private _router: Router) {
		this.componentName = 'QoreComponent';
	}

	ngOnInit(): void {
		// 1. Security Initialization
		this._authState$ = this._authService.state$;

		// This starts up the token refresh process for the app
		this._authService.init()
			.subscribe(
				() => {
					this._logger.info(`${this.componentName}:ngOnInit`, 'Authentication Service Initialize Ok');
				},
				error => {
					this._logger.warn(`${this.componentName}:ngOnInit`, 'Authentication Service Initialize Not Ok', error);
				}
			);

		// 2. Translation
		// Subscribe to translation service language change -> and change direction of components and insert respective language
		if (this._translateSubscription == null) {
			this._translateSubscription =
				this._translateService.onLangChange.subscribe(
					(params: LangChangeEvent) => {
						if (!isNullOrUndefined(this._qoreConfig) &&
							!isNullOrUndefined(this._qoreConfig.configurations) &&
							!isNullOrUndefined(this._qoreConfig.configurations.availableLanguages)) {
							this._renderer.setAttribute(this._elRef.nativeElement, 'dir',
								this._qoreConfig.configurations.availableLanguages.find(
									language => language.langCode.includes(this._translateService.currentLang)).direction);

							this._renderer.setAttribute(this._elRef.nativeElement, 'lang',
								this._translateService.currentLang.split('-')[0].toLowerCase());
						}
					});
		}

		// 3. Authentication Service (watch for changes in user observable)
		// Subscribe to authentication User changes ... to change translation service
		// language (permissions changes can also be checked here)
		if (!isNullOrUndefined(this._authService.user$)) {
			this._userSubscription = this._authService.user$.subscribe(
				(user: Profile) => {
					if (!isNullOrUndefined(user)) {
						this._translateService.use(user.lang);

						// Change language cookie (because it's empty)
						this._cookieService.set('QoreLang', user.lang,
							new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
							'/'
						);
					}
				});
		}

		const browserLang = this._translateService.getBrowserLang();
		const cookieLang = this._cookieService.get('QoreLang');

		// Check to see if cookie language code exists in available languages list
		const newLanguageCodeExists: boolean = this._qoreConfig.configurations.availableLanguages.some(language => language.langCode === cookieLang);

		this._logger.debug(`${this.componentName}:ngOnInit`, 'Browser Language', browserLang, 'Cookie',
			'QoreLang', 'Current Cookie Language:', cookieLang);

		let languageCode: string;

		// If cookie language exists and is available in languages list, use cookie language
		if (cookieLang && newLanguageCodeExists) {
			languageCode = cookieLang;
		} else {
			// If no language cookie exists check to see if there is a similar language for browser language and if not use default
			// browser language is in the format (en, de, etc.)
			const lang: Language =
				this._qoreConfig.configurations.availableLanguages.find(
					language => language.langCode.includes(browserLang) && language.isDisabled === false);

			languageCode = lang.langCode != null ? lang.langCode : this._qoreConfig.configurations.defaultLanguage;
		}

		// Set cookie language
		this._cookieService.set('QoreLang', languageCode, new Date(new Date().setFullYear(new Date().getFullYear() + 2)), '/');

		this._translateService.use(languageCode);

		// Change language in authentication service ... because can be different (not being updated in Api right now)
		this._authService.changeLanguage(languageCode);
	}

	ngOnDestroy() {
		// Unsubscribe
		if (!isNullOrUndefined(this._translateSubscription)) { this._translateSubscription.unsubscribe(); }
		if (!isNullOrUndefined(this._userSubscription)) { this._userSubscription.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	*/
	refreshToken() {
		this._authService.refreshTokens()
			.subscribe(
				result => {
					this._logger.debug(`${this.componentName}:refreshToken`, 'Started', result);
				},
				error => {
					this._logger.error(`${this.componentName}:refreshToken`, 'Error', error);

					// If here session is expired and refreshed failed, so redirect to login
					this._router.navigate(['/login']);
				}
			);
	}
}
