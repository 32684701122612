<perfect-scrollbar>
    <!-- TERMS AND CONDITIONS -->
    <div id="terms-and-conditions">
        <div id="content">
            <ng-container *ngIf="curLangCode === 'pt-PT'">
                <h4>Termos e Condições do serviço Qore</h4>
                <h5><b>1.</b> Âmbito e Objeto das Condições Gerais do serviço</h5>
                <p>
                    As presentes Condições Gerais destinam-se a regular os termos e as condições por que se regerá a
                    prestação do serviço
                    <b>Qore</b> pela <b>Qevo</b> (doravante designada por "Qevo") aos <b>Utilizadores</b> (doravante
                    designados por "Utilizador" ou
                    "Utilizadores") nas lojas dos nossos <b>Clientes</b> (doravante designado por "Clientes").
                    <br><br>
                    O serviço em causa consiste na disponibilização de um serviço gestão de filas de espera, tanto com
                    senhas ou marcações,
                    com atendimento presencial ou remoto dos utilizadores, nos termos e condições aqui descritos.
                </p>
                <h5><b>2.</b> Responsabilidades</h5>
                <p><b>2.1.</b> Todos os serviços prestados pela Qevo estão de acordo com a Lei Portuguesa.</p>
                <p>
                    <b>2.2.</b> O Qore possui os níveis adequados de segurança, contudo a Qevo não será responsável por
                    quaisquer prejuízos sofridos
                    pelo Utilizado, em virtude de atrasos, interrupções, erros e suspensões de comunicações que tenham
                    origem em fatores
                    fora do seu controlo.
                </p>
                <p>
                    <b>2.3.</b> A Qevo não será responsável por quaisquer perdas ou danos causados por utilizações
                    abusivas do serviço que não lhe sejam
                    diretamente imputáveis a título de dolo ou culpa grave.
                </p>
                <h5><b>3.</b> Obrigações do Consumidor</h5>
                <p>
                    <b>3.1.</b> O utilizador compromete-se a: <br>
                    <b>&emsp;&emsp;i)</b> Facultar dados pessoais corretos <br>
                    <b>&emsp;&emsp;ii)</b> Não utilizar dados falsos <br>
                    <b>&emsp;&emsp;iii)</b> Não usar o sistema de forma abusiva
                </p>
                <p>
                    <b>3.2.</b> Caso algum dos dados esteja incorreto ou insuficiente, e por esse motivo seja impossível
                    prestar o serviço, a
                    responsabilidade é do Utilizador, sendo que a Qevo declina qualquer responsabilidade.
                    No caso de o utilizador violar alguma destas obrigações, a Qevo reserva-se no direito de eliminar o
                    fornecimento de
                    qualquer serviço disponibilizado pela Qevo ao mesmo Utilizador.
                </p>
                <h5><b>4.</b> Privacidade e Proteção de Dados Pessoais</h5>
                <p>
                    <b>4.1.</b> A Qevo garante a confidencialidade de todos os dados fornecidos pelos Utilizadores.
                </p>
                <p>
                    <b>4.2.</b> Os dados pessoais requeridos pela solução e facultados pelo Utilizador (por exemplo,
                    durante a tiragem de senha ou no
                    preenchimento do formulário de marcação para a sua reserva) e que sejam de fornecimento obrigatório
                    são indispensáveis à
                    prestação do Serviço pela Qevo e pelo Cliente. A omissão ou inexatidão dos dados fornecidos pelo
                    Utilizador são da sua
                    única e inteira responsabilidade.
                </p>
                <p>
                    <b>4.3.</b> Os dados pessoais do Utilizador serão processados e armazenados informaticamente e
                    destinam-se a ser usados pela Qevo e
                    pelo Cliente no âmbito dos serviços prestados (podendo ser partilhados a terceiros para o
                    fornecimento dos serviços
                    contratados), analise de comportamentos, estatísticas, melhoramento da experiência do Utilizador e
                    resolução de queixas.
                </p>
                <p>
                    <b>4.4.</b> Nos termos da legislação aplicável, é garantido ao Utilizador, sem encargos adicionais,
                    o direito de acesso,
                    retificação, eliminação e atualização dos seus dados pessoais, mediante pedido por escrito, bom como
                    o direito de
                    oposição à utilização dos mesmos para as finalidades previstas no número anterior.
                </p>
                <p>O Utilizador pode exercer os referidos direitos mediante pedido escrito dirigido à Qevo para o
                    endereço de email <a href="mailto: info@qevo.pt">info@qevo.pt</a>. <br><br>
                    O Utilizador tem ainda o direito de retirar o seu consentimento para o tratamento dos dados cujo
                    consentimento constitui
                    o fundamento de legitimidade do respetivo tratamento. Para o efeito, tem o direito de retirar o seu
                    consentimento a
                    qualquer momento, o que não invalida, no entanto, o tratamento efetuado até essa data com base no
                    seu consentimento previamente dado.
                </p>
                <p class="center"><b>Lei Aplicável</b></p>
                <p class="center">O Contrato rege-se pela lei portuguesa.</p>
                <p class="center">Data de publicação: Agosto 2022</p>
            </ng-container>

            <ng-container *ngIf="curLangCode === 'en-GB'">
                <h4>Qore Terms and Conditions</h4>
                <h5><b>1.</b> Scope and General Conditions of Service Object</h5>
                <p>
                    These General Conditions are intended to regulate the terms and conditions governing
                    the provision of the service <b>Qore</b> by <b>Qevo</b> (hereinafter referred to as
                    "Qevo") to
                    <b>Users</b> (hereinafter referred to as "User" or "Users") in
                    our <b>Clients</b> stores (hereinafter referred to as "Clients").
                    <br><br>
                    The service in question consists of providing a queue management service, either with tickets or
                    bookings, with
                    in-person or remote service, under the terms and conditions described herein.
                </p>
                <h5><b>2.</b> Responsibilities</h5>
                <p><b>2.1.</b> All services provided by Qevo are in accordance with Portuguese Law.</p>
                <p>
                    <b>2.2.</b> Qore has the appropriate levels of security, however Qevo will not be liable for any
                    damages suffered by the User due to
                    delays, interruptions, errors and suspensions of communications arising from factors beyond its
                    control.
                </p>
                <p>
                    <b>2.3.</b> Qevo will not be liable for any loss or damage caused by misuse of the service that
                    is not directly attributable to it by intent or serious fault.
                </p>
                <h5><b>3.</b> Consumer Obligations</h5>
                <p>
                    <b>3.1.</b> The user undertakes to: <br>
                    <b>&emsp;&emsp;i)</b> Provide correct personal data <br>
                    <b>&emsp;&emsp;ii)</b> Do not use false data <br>
                    <b>&emsp;&emsp;iii)</b> Not abusing the system
                </p>
                <p>
                    <b>3.2.</b> If any of the data is incorrect or insufficient, and therefore it's impossible to
                    provide the service, the responsibility is of the User, and Qevo declines any responsibility.
                    Should you breach any of these obligations, Qevo reserves the right to terminate the provision
                    of
                    any other services made available simultaneously by Qevo to the same User.
                </p>
                <h5><b>4.</b> Privacy and Protection of Personal Data</h5>
                <p>
                    <b>4.1.</b> Qevo guarantees the confidentiality of all data provided by Users.
                </p>
                <p>
                    <b>4.2.</b> The personal data required by the solution and provided by the User (for example, when
                    issuing a ticket or filling in
                    the booking form for your reservation) and which are mandatory to provide are essential for the
                    provision of the Service
                    by Qevo and the Client. The omission or inaccuracy of the data provided by the User are their sole
                    and entire
                    responsibility.
                </p>
                <p>
                    <b>4.3.</b> The User's personal data will be processed and stored by computer and are intended to be
                    used by Qevo and the Client
                    within the scope of the services provided (which may be shared with third parties to provide the
                    contracted services),
                    analysis of behavior, statistics, improvement of the experience of the User and resolution of
                    complaints.
                </p>
                <p>
                    <b>4.4.</b> Pursuant to applicable law, the User is guaranteed, at no additional charge, the
                    right to access, rectify, delete and update his / her personal data upon written request, as
                    well
                    as the right to object to their use for the purposes set forth in previous paragraph.
                </p>
                <p>The User may exercise these rights upon written request to Qevo at <a
                        href="mailto: info@qevo.pt">info@qevo.pt</a>. <br><br>
                    The User also has the right to withdraw his consent to the processing of data whose consent
                    constitutes the basis for the legitimacy of the processing. To this end, the User has the right
                    to withdraw his consent at any time, but this does not invalidate the treatment done until that
                    date on the basis of his prior consent.</p>
                <p class="center"><b>Applicable law</b></p>
                <p class="center">The Contract is governed by Portuguese law.</p>
                <p class="center">Date Posted: August 2022</p>
            </ng-container>

            <ng-container *ngIf="curLangCode === 'es-ES'">
                <h4> Términos y condiciones del servicio Qore </h4>
                <h5> <b>1.</b> Alcance y Objeto de las Condiciones Generales del Servicio </h5>
                <p>
                    Estas Condiciones Generales tienen por objeto regular los términos y condiciones que rigen la
                    prestación
                    del servicio <b> Qore </b> por <b> Qevo </b> (en adelante, "Qevo") a <b> Usuarios. </b>
                    (en
                    adelante, "Usuario" o "Usuarios") en las tiendas de estos <b> Clientes </b> (en adelante,
                    "Clientes").
                    <br> <br>
                    El servicio en cuestión consiste en la prestación de un servicio de gestión de colas, ya sea con
                    ticket o cita previa,
                    con atención presencial o remota a los usuarios, en los términos y condiciones aquí descritos.
                </p>
                <h5> <b>2.</b> Responsabilidades </h5>
                <p> <b>2.1.</b> Todos los servicios proporcionados por Qevo están de acuerdo con la ley portuguesa.
                </p>
                <p>
                    <b>2.2.</b> Qore cuenta con niveles adecuados de seguridad, sin embargo Qevo no será responsable de
                    los daños sufridos por el
                    Usuario, debido a retrasos, interrupciones, errores y suspensiones de comunicaciones derivadas de
                    factores externos.
                </p>
                <p>
                    <b>2.3</b> Qevo no será responsable de ninguna pérdida o daño causado por el mal uso del servicio
                    que
                    no sea directamente atribuible a él como dolo o negligencia grave.
                </p>
                <h5> <b>3.</b> Obligaciones del cliente </h5>
                <p>
                    <b> 3.1.</b> El usuario se compromete a: <br>
                    <b>&emsp;&emsp;i) </b> Proporcionar datos personales correctos <br>
                    <b>&emsp;&emsp;ii) </b> No usar datos falsos <br>
                    <b>&emsp;&emsp;iii) </b> No hacer un mal uso del sistema
                </p>
                <p>
                    <b>3.2.</b> Si alguno de los datos es incorrecto o insuficiente, y por este motivo es imposible
                    prestar
                    el servicio, el Usuario es responsable, y Qevo declina cualquier responsabilidad. En caso de que el
                    Usuario viole alguna de estas obligaciones, Qevo se reserva el derecho de eliminar la prestación de
                    cualquier otro servicio proporcionado simultáneamente por Qevo al mismo Usuario.
                </p>
                <h5> <b>4.</b> Privacidad y protección de datos personales </h5>
                <p>
                    <b>4.1.</b> Qevo garantiza la confidencialidad de todos los datos proporcionados por los Usuarios.
                </p>
                <p>
                    <b>4.2.</b> Los datos personales requeridos por la solución y facilitados por el Usuario (por
                    ejemplo, al emitir un ticket o al
                    cumplimentar el formulario de reserva) y que son obligatorios de facilitar son imprescindibles para
                    la prestación del
                    Servicio por parte de Qevo y el Cliente. La omisión o inexactitud de los datos facilitados por el
                    Usuario son de su
                    exclusiva y entera responsabilidad.
                </p>
                <p>
                    <b>4.3.</b> Los datos personales del Usuario serán tratados y almacenados informáticamente y están
                    destinados a ser utilizados por
                    Qevo y el Cliente en el ámbito de los servicios prestados (que podrán ser compartidos con terceros
                    para la prestación de
                    los servicios contratados), análisis de comportamiento, estadísticas, mejora de la experiencia del
                    Usuario y resolución
                    de reclamaciones.
                </p>
                <p>
                    <b>4.4.</b> De acuerdo con la ley aplicable, se garantiza al Usuario, sin cargos adicionales, el
                    derecho a acceder, rectificar, eliminar y actualizar sus datos personales, previa solicitud por
                    escrito,
                    así como el derecho a oponerse al uso de utilizarlos para las finalidades indicadas en el número
                    anterior.
                </p>
                <p> El Usuario puede ejercitar estos derechos mediante una solicitud por escrito dirigida a Qevo a la
                    dirección de correo electrónico <a href="mailto: info@qevo.pt">info@qevo.pt</a>. <br> <br> El
                    Usuario también tiene derecho a
                    retirar su consentimiento al tratamiento de datos cuyo consentimiento constituye la base de
                    legitimación
                    para el respectivo tratamiento. A tal efecto, tiene derecho a retirar su consentimiento en cualquier
                    momento, lo que, sin embargo, no invalida el tratamiento realizado hasta esa fecha en base a su
                    consentimiento previamente otorgado. </p>
                <p class="center"><b>Ley aplicable</b></p>
                <p class="center">El contrato se rige por la ley portuguesa.</p>
                <p class="center">Fecha de publicación: Agosto de 2022</p>
            </ng-container>

            <ng-container *ngIf="curLangCode === 'fr-FR'">
                <h4> Conditions générales du service Qore </h4>
                <h5> <b>1.</b> Portée et objet des Conditions Générales de Service </h5>
                <p>
                    Les présentes conditions générales ont pour objet de réglementer les termes et conditions qui
                    régiront
                    la fourniture du service. <b> Qore </b> par <b> Qevo </b> (ci-après dénommé "Qevo"),
                    aux
                    <b> Utilisateurs. </b> (ci-après dénommé "Utilisateur" ou "Utilisateurs") dans les magasins de
                    nos
                    <b>Clients</b> (ci-après dénommés "Clients").
                    <br> <br>
                    Le service en question consiste à fournir, par le biais de notre application, d'alertes par SMS,
                    selon
                    les modalités décrites dans les présentes.
                </p>
                <h5> <b>2.</b> Responsabilités </h5>
                <p> <b>2.1.</b> Tous les services fournis par Qevo sont conformes à la legislation Portugaise. </p>
                <p>
                    <b>2.2.</b> Qore a des niveaux de sécurité adéquats, cependant Qevo ne sera pas responsable des
                    dommages subis par l'utilisateur,
                    dus à des retards, interruptions, erreurs et suspensions de communications dérivés de facteurs
                    externes.
                </p>
                <p>
                    <b>2.3.</b> Qevo ne sera pas responsable de toute perte ou dommage causé par une mauvaise
                    utilisation
                    du service qui ne lui est pas directement imputable comme intention ou négligence grave.
                </p>
                <h5> <b>3.</b> Obligations du client </h5>
                <p>
                    <b>3.1.</b> L'utilisateur s'engage à : <br>
                    <b>&emsp;&emsp;i) </b> Fournissez des données personnelles correctes <br>
                    <b>&emsp;&emsp;ii) </b> N'utilisez pas de fausses données <br>
                    <b>&emsp;&emsp;iii) </b> Ne pas abuser du système
                </p>
                <p>
                    <b> 3.2 </b> Si l'une des données est incorrecte ou insuffisante, et pour cette raison, il est
                    impossible de fournir le service, la responsabilité en incombe à l'Utilisateur, et Qevo décline
                    toute
                    responsabilité. En cas de manquement pour part de l'utilisateur à l'une de ces obligations, Qevo se
                    réserve le droit de supprimer la fourniture de tout autre service fourni simultanément par Qevo au
                    même
                    Utilisateur.
                </p>
                <h5> <b>4.</b> Confidentialité et protection des données personnelles </h5>
                <p>
                    <b>4.1.</b> Qevo garantit la confidentialité de toutes les données fournies par les Utilisateurs.
                </p>
                <p>
                    <b>4.2.</b> Les données personnelles requises par la solution et fournies par l'Utilisateur (par
                    exemple, lors de l'émission d'un
                    ticket ou du remplissage du formulaire de réservation pour votre réservation) et qu'il est
                    obligatoire de fournir sont
                    indispensables à la fourniture du Service par Qevo et le Client. L'omission ou l'inexactitude des
                    données fournies par
                    l'Utilisateur relèvent de sa seule et entière responsabilité.
                </p>
                <p>
                    <b>4.3.</b> Les données personnelles de l'Utilisateur seront traitées et stockées informatiquement
                    et sont destinées à être
                    utilisées par Qevo et le Client dans le cadre des services fournis (qui peuvent être partagés avec
                    des tiers pour
                    fournir les services contractuels), analyse du comportement, statistiques, amélioration de
                    l'expérience de l'Utilisateur
                    et résolution des réclamations.
                </p>
                <p>
                    <b>4.4.</b> Aux termes de la législation applicable, l'Utilisateur se voit garantir, sans frais
                    supplémentaires, le droit d'accès, de rectification, de suppression et de mise à jour de ses données
                    personnelles, sur demande écrite, ainsi que le droit de s'opposer à l'utilisation de celles-ci aux
                    fins
                    énoncées au paragraphe précédent.
                </p>
                <p> L'Utilisateur peut exercer ces droits par demande écrite adressée à Qevo à l'adresse électronique
                    <a href="mailto: info@qevo.pt">info@qevo.pt</a>. <br><br>L'utilisateur a également le droit de
                    retirer son consentement pour le
                    traitement des données pour lesquelles le consentement est la base de la légitimité du traitement. À
                    cette fin, l'utilisateur a le droit de retirer son consentement à tout moment, ce qui n'invalide
                    toutefois pas le traitement effectué jusqu'à cette date sur la base de son consentement précédemment
                    donné.
                </p>
                <p class="center"><b>Droit applicable</b></p>
                <p class="center">Le contrat est régi par le droit portugais.</p>
                <p class="center">Date de publication : Août 2022</p>
            </ng-container>
        </div>
    </div>
</perfect-scrollbar>