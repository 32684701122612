/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Core Module
* @description
* Core Components
* Created by Carlos.Moreira @ 2018/02/21
*/

// Angular Components
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/compiler/src/core';

// Translation
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// For responsive specific components
import { ResponsiveModule } from 'ngx-responsive';

// Perfect Scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// Drag and Drop (Dragula)
import { DragulaModule } from 'ng2-dragula';

// Date directive
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

// Core Components
import { LoginComponent } from './components/login/login.component';
import { Error404Component } from './components/errors/error-404/error-404.component';
import { QoreModuleShared } from '../shared/shared.module';
import { LanguageComponent } from './components/language/language.component';
import { UserComponent } from './components/user/user.component';
import { HomeComponent } from './components/home/home.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CoreHttpLoaderFactory } from './others/translation.factory';
import { ErrorComponent } from './components/errors/error/error.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

// Core Services
import { AuthGuard } from './guards/auth-guard.service';

// Qore Environments
import { environment } from '../../environments/environment.local';

// Qevo/Qore/Counter Libraries
import {
	QevoServicesModule, QevoServicesConfiguration, ResponsiveService,
	ResponsiveConfig, ResizeService, LoggerService, TokenInterceptor, ResponseInterceptor
} from 'qevo.services';
import { QevoUtilitiesPipesModule } from 'qevo.utilities';
import { UsersService, ConsoleService } from 'qore.services';

// Qevo Services Configuration Library Configurations
// NOTE:
// 	- Needs to be here because of the environment variable above that isn't recognize in a
// 	  separated file
// Added by Carlos.Moreira @ 2018/11/20
export const QevoServicesLibraryConfiguration: QevoServicesConfiguration = {
	loggerConfig: environment.configurations.logger,
	notificationConfig: {
		url: environment.configurations.signalRUrl,
		retries: {
			maxNumberOfRetries: environment.configurations.notification.maxNumberOfRetries,
			minTimeSpanLoggedToResetMaxNumberOfRetries: environment.configurations.notification.minTimeSpanLoggedToResetMaxNumberOfRetries,
			timeBetweenRetries: environment.configurations.notification.timeBetweenRetries,
			maxNumberOfRecoverRetries: environment.configurations.notification.maxNumberOfRecoverRetries,
			timeBetweenRecoverRetries: environment.configurations.notification.timeBetweenRecoverRetries,
			reloadAfterRecoverRetriesFailed: environment.configurations.notification.reloadAfterRecoverRetriesFailed,
			maxNumberOfReloadAfterFailedRecoverRetries: environment.configurations.notification.maxNumberOfReloadAfterFailedRecoverRetries
		}
	},
	authConfig: {
		url: environment.configurations.authenticationUrl,
		urlsToAddAuthenticationToken: [
			environment.configurations.consoleApiUrl,
			environment.configurations.managerApiUrl,
			environment.configurations.authenticationUrl,
			environment.configurations.counterApiUrl,
			environment.configurations.insightsApiUrl,
			environment.configurations.bookingsApiUrl,
			environment.configurations.bookingsInsightsApiUrl
		],
		security: null
	},
	thirdParty: environment.configurations.thirdParty
};

@NgModule({
	declarations: [
		LoginComponent,
		Error404Component,
		LanguageComponent,
		UserComponent,
		HomeComponent,
		SidebarComponent,
		ErrorComponent,
		TermsAndConditionsComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		TranslateModule,
		ResponsiveModule,
		QoreModuleShared,
		RouterModule,
		ReactiveFormsModule.withConfig({
			warnOnNgModelWithFormControl: 'always'
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (CoreHttpLoaderFactory),
				deps: [HttpClient],
			}
		}),
		PerfectScrollbarModule,
		DragulaModule.forRoot(),
		AngularMyDatePickerModule,
		QevoServicesModule.forRoot(QevoServicesLibraryConfiguration),
		QevoUtilitiesPipesModule
	]
})
export class QoreModuleCore {
	constructor(@Optional() @SkipSelf() parentModule: QoreModuleCore) {
		if (parentModule) {
			throw new Error(
				'QoreModuleCore is already loaded. Import it in the QoreModule only');
		}
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: QoreModuleCore,
			providers: [
				AuthGuard,
				LoggerService,
				UsersService,
				ConsoleService,
				ResponsiveService,
				{
					provide: ResponsiveConfig,
					useValue: null				// Force to use the default responsive values
				},
				ResizeService,
				{
					provide: HTTP_INTERCEPTORS,
					useClass: TokenInterceptor,
					multi: true
				},
				{
					provide: HTTP_INTERCEPTORS,
					useClass: ResponseInterceptor,
					multi: true
				}
			]
		};
	}

	static forChild(): ModuleWithProviders {
		return {
			ngModule: QoreModuleCore,
		};
	}
}
