/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Authentication Guard
* @description
* Core Authentication Guard
* Created by Carlos.Moreira @ 2018/02/23
*/

// Angular Components
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Data, CanActivateChild, Route, CanLoad } from '@angular/router';
import { of as observableOf, Observable } from 'rxjs';

// Libraries Components
import { Roles } from 'qevo.models';
import { LoggerService, AuthService } from 'qevo.services';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/
	// Guard Name
	protected guardName: string;

	protected _isLoggedIn: boolean;
	protected _userRoles: Roles[];

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		protected _authService: AuthService,
		protected _logger: LoggerService,
		protected _router: Router) {

		this.guardName = 'AuthGuard';

		// Get user "logged in" flag
		this._authService.loggedIn$.subscribe(loggedIn => {
			if (loggedIn != null) {
				this._isLoggedIn = loggedIn;
			}
		});

		// Get user permissions (only if user exists and changes)
		this._authService.getUser().subscribe(user => {
			this._userRoles = this._authService.getUserProfileRoles();
		});
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods / Events
	 * ********************************************************************************************************************************
	*/

	/**
	 * Can Activate event
	 * @param route Current route
	 * @param state Router state snapshot
	 */
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// this._logger.info('AuthGuard:canActivate', 'Route:', route.url, 'Logged In?', this._isLoggedIn, 'Roles:', this._userRoles);
		const url: string = state.url;

		// checks to see if user is logged into the system
		if (this.checkLogin(url)) {
			return observableOf(this.checkPermissions(url, route.data));
		}

		// If here ... means no access is granted ...
		return observableOf(false);
	}

	/**
	 * Can Activate Child event
	 * @param route Current route
	 * @param state Router state snapshot
	 */
	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		// console.log('-> AuthGuard:canActivateChild', route, state);
		// this._logger.info('AuthGuard:canActivateChild', 'Route:', route.url, 'Logged In?', this._isLoggedIn, 'Roles:', this._userRoles);
		return this.canActivate(route, state);
	}

	/**
	 * Checks to see if the user can load the respective Route (Module)
	 * @param route Route to be loaded
	 */
	canLoad(route: Route): Observable<boolean> {
		const url = `/${route.path}`;

		// checks to see if user is logged into the system
		if (this.checkLogin(url)) {
			return observableOf(this.checkPermissions(url, route.data));
		}

		// If here ... means no access is granted ...
		return observableOf(false);
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	*/

	/**
	 * Checks to see if user as permissions to access the requested url
	 * @param url Url being requested
	 */
	private checkPermissions(url: string, data: Data): boolean {
		// User has permissions to access route
		// this._logger.info('AuthGuard:checkPermissions', url, data, data.roles, this._userRoles);

		// No data in router, so return true
		if (data === null || data.roles === null || data.roles === undefined) {
			return true;
		} else {
			// Go throw all user roles and match them against route roles
			return this._userRoles.some(role => data.roles.some((routeRole: Roles) => routeRole === role));
		}
	}

	/**
	 * Checks to see if user is logged into the system
	 * @param url Url being requested
	 */
	private checkLogin(url: string): boolean {
		// User is logged in ?
		if (this._isLoggedIn) {
			// If logged into the system ... means that user has access ... so return true
			return true;
		}

		// Store the attempted URL for redirecting
		this._authService.redirectUrl = url;

		// Navigate to the login page with extras
		this._router.navigate(['/login']);

		return false;
	}
}
