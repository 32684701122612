/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Qore Routings
* @description
* Global Routings
* Created by Carlos.Moreira @ 2018/01/29
*/

// Angular Components
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Qore Components
import { AuthGuard } from './guards/auth-guard.service';

// Core Components
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { Error404Component } from './components/errors/error-404/error-404.component';
import { ErrorComponent } from './components/errors/error/error.component';

// Libraries Components
import { MenuType, Roles } from 'qevo.models';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
	{
		path: '',
		component: HomeComponent,
		canActivate: [AuthGuard],
		data: {
			isRoot: true
		},
		children: [
			{
				path: 'bookings',
				loadChildren: () =>
					import('../bookings/bookings-wrapper.module').then(m =>
						m.QoreBookingsWrapperModule),
				canLoad: [AuthGuard],
				data:
				{
					path: '/bookings',
					name: 'MENU.BOOKINGS',
					class: 'fa fa-book',
					type: MenuType.root,
					available: true,
					roles: [
						Roles.SuperUser,
						Roles.Partner,
						Roles.Administrator,
						Roles.Manager,
						Roles.BookingsAdministrator,
						Roles.BookingsManager,
						Roles.InsightsManager
					]
				}
			},
			{
				path: 'insights',
				loadChildren: () => import('../insights/insights.module').then(m => m.InsightsModule),
				canLoad: [AuthGuard],
				data:
				{
					path: '/insights',
					name: 'MENU.INSIGHTS',
					class: 'fa fa-bar-chart',
					type: MenuType.root,
					available: true,
					roles: [
						Roles.SuperUser,
						Roles.InsightsAdministrator,
						Roles.Partner
					]
				}
			},
			{
				path: 'manager',
				loadChildren: () => import('../manager/manager.module').then(m => m.ManagerModule),
				canLoad: [AuthGuard],
				data:
				{
					path: '/manager',
					name: 'MENU.MANAGER',
					class: 'fa fa-cogs',
					available: true,
					type: MenuType.root,
					roles: [
						Roles.SuperUser,
						Roles.Administrator,
						Roles.Manager,
						Roles.Partner,
						Roles.InsightsManager
					]
				}
			},
			{
				path: 'counter',
				loadChildren: () => import('../counter/counter-wrapper.module').then(m => m.CounterWrapperModule),
				canLoad: [AuthGuard],
				data:
				{
					path: '/counter',
					name: 'MENU.COUNTER',
					// eslint-disable-next-line max-len
					svg: 'M212.453 218.799h-15.167v27.3h-18.2v-27.3H163.92c-25.089 0-45.5 20.412-45.5 45.5v9.101h139.534v-9.101c0-25.089-20.411-45.5-45.501-45.5zM188.187 100.498c-25.089 0-45.5 20.412-45.5 45.5v9.1c0 25.088 20.411 45.5 45.5 45.5s45.5-20.412 45.5-45.5v-9.1c0-25.088-20.411-45.5-45.5-45.5zM276.154 191.499h116.481v81.9H276.154zM100.22 327.999h310.614v83.114H100.22zM100.22 291.599h310.614v18.2H100.22z',
					available: true,
					type: MenuType.root,
					roles: [
						Roles.SuperUser,
						Roles.Administrator,
						Roles.Manager,
						Roles.Partner,
						Roles.Employee
					]
				}
			}
		]
	},
	// login page
	{ path: 'login', component: LoginComponent },

	// login page
	{ path: 'terms-and-conditions', component: TermsAndConditionsComponent },
	{ path: 'terms-and-conditions/:langCode', component: TermsAndConditionsComponent },

	// error pages
	{ path: '404', component: Error404Component },
	{ path: 'error', component: ErrorComponent },
	{ path: 'error/:errorCode/:errorMessage', component: ErrorComponent },

	// otherwise redirect to 404
	{ path: '**', redirectTo: '/404' }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { enableTracing: false })
	],
	exports: [
		RouterModule
	]
})
export class CoreRoutingModule { }
