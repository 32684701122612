import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { QoreModule } from './app/qore.module';
import { environment } from './environments/environment.local';

import { hmrBootstrap } from './hmr';

// Need because of Hot Module Reload in Angular 9 after changing code or library components
// Added by Carlos.Moreira @ 2020/05/28
import '@angular/compiler';

if (environment.production) {
	enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(QoreModule, { preserveWhitespaces: true });

if (environment.hmr) {
	if (module['hot']) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error('HMR is not enabled for webpack-dev-server!');
		console.log('Are you using the --hmr flag for ng serve?');
	}
} else {
	bootstrap();
}
