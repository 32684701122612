<div class="idiom" (click)="this.showLang = !this.showLang" (clickOutside)="this.showLang = false"
	[clickOutsideEvents]="'click,touchstart'">
	<img src="./assets/img/earth.svg" alt="{{selectedLangCode}}">{{ 'GLOBAL.LANGUAGE_ABR' | translate }}
	<ul class="drop-down" *ngIf="showLang === true">
		<li *ngFor="let lang of availableLanguages" (click)="switchLang(lang)"
			[ngClass]="{'selected': isSelected(lang)}">
			{{lang.display}}
		</li>
	</ul>
</div>
