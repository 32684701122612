/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @function ResponsiveDefinition
* @description
* For responsive specific components
* Created by Carlos.Moreira @ 2018/02/22
*/
import { ResponsiveConfig } from 'ngx-responsive';

// Config for ngx-responsive
const config = {
	breakPoints: {
		xs: { max: 320 },
		sm: { min: 321, max: 479 },
		md: { min: 480, max: 767 },
		lg: { min: 768, max: 1169 },
		xl: { min: 1170 }
	},
	debounceTime: 100 // allow to debounce checking timer
};
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function ResponsiveDefinition() {
	return new ResponsiveConfig(config);
}
