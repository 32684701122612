/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Home Component
* @description
* Home Component
* Created by Carlos.Moreira @ 2018/01/26
*/
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';
import { ResizeService, LoggerService } from 'qevo.services';
import { EventType } from 'qevo.models';
import { ConsoleService } from 'qore.services';

@Component({
	selector: 'qore-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/
	protected componentName: string;

	// Navigation subscription
	private _stateServiceNavigationSub: Subscription;

	// Hide mobile sidebar menu for UI
	public hideMobileSidebarMenu: boolean;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		private _consoleService: ConsoleService,
		private _logger: LoggerService,
		private _resizeService: ResizeService) { //+ IMPORTANT: Despite not using here ... We must inject service at the top to have only one instance
		this.componentName = 'HomeComponent';
	}

	ngOnInit() {
		// Initialize console
		this._consoleService.initialize();

		// Get navigation events to this component
		this._stateServiceNavigationSub =
			this._consoleService.getState(EventType.Navigation)
				.pipe(
					filter(data =>
						!isNullOrUndefined(data) &&
						!isNullOrUndefined(data.state) &&
						data.to === this.componentName &&
						data.state.action === 'counter-panels-navigation'),
					distinctUntilChanged()
				).subscribe(
					(data) => {
						this.hideMobileSidebarMenu = !data.state.value;
						this._logger.debug(`${this.componentName}:ngOnInit`,
							'Navigation Event - counter-panels-navigation', 'data', data,
							'Hide mobile sidebar menu', this.hideMobileSidebarMenu);
					});
	}

	ngOnDestroy() {
		if (!isNullOrUndefined(this._consoleService)) { this._consoleService.destroy(); }
		if (!isNullOrUndefined(this._stateServiceNavigationSub)) { this._stateServiceNavigationSub.unsubscribe(); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	*/
	/**
	 * Mouse hover event to check if side bar is open, so it can be listened by
	 * the parents of ContextBarNavigationComponent
	 * @param state boolean
	 */
	isSidebarOpenEvent(state: boolean) {
		// Send event to parent of ContextBarNavigationComponent
		this._consoleService.setState(
			{
				from: this.componentName,
				type: EventType.Data,
				to: 'ContextBarNavigationComponent',
				state: {
					action: 'is-sideBar-open',
					value: state
				}
			});
	}
}
