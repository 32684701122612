<div id="user" (click)="toggleUserOptions(true)" (clickOutside)="toggleUserOptions(false)"
	[clickOutsideEvents]="'click,touchstart'">

	<!-- ======  Main bar user info ======= -->
	<div id="avatar">
		<span *ngIf="newNotifications != 0" id="notifications">{{newNotifications}}</span>
		<div id="img-container">
			<img *ngIf="currentUser.avatar" src="{{currentUser.avatar}}" alt="{{'USER.AVATAR' | translate}}">
			<img *ngIf="!currentUser.avatar" src="./assets/img/manager/listing/default-user-img.svg"
				alt="{{'USER.AVATAR' | translate}}">
		</div>
	</div>
	<div id="info">
		<p>
			<span>{{'USER.HI' | translate}}</span>
			<br>{{currentUser.username | qtruncate : 5 : ''}}
			<i class="fa fa-angle-right" id="right-arrow"></i>
			<br>
			<i class="fa fa-globe" id="globe"></i>
			<span id="idiom">{{ 'GLOBAL.LANGUAGE_ABR' | translate }}</span>
		</p>
	</div>

	<!-- ======  User bar info ======= -->
	<div id="user-options" (click)="toggleUserOptions(true)" [class.open-user-options]="visibleUserOptions">
		<div id="user-options-user-info" class="safe-margins">
			<span id="name" dynamicTruncate ticker="true" [value]="currentUser.username" limit="15"></span>
			<span>{{currentUser.full_name}}</span>
			<span>{{currentUser.nickname}}</span>
			<qore-language></qore-language>
		</div>

		<!-- ======  User bar settings info + form trigger ======= -->
		<div (clickOutside)="showPasswordForm = false">
			<div id="user-settings-trigger" (click)="showPasswordForm = !showPasswordForm"
				[class.open]="showPasswordForm">
				<span class="safe-margins">
					<i class="fa fa-cog" id="settings"></i>
					{{'USER.ACCOUNT_SETTINGS' | translate}}
					<i id="arrow-toggle" class="fa" [ngClass]="showPasswordForm ? 'fa-angle-up' : 'fa-angle-down'"></i>
				</span>
			</div>
			<!-- ======  User bar settings form ======= -->
			<div
				[ngClass]="changePasswordErrors != null ? (changePasswordErrors.isError ? 'account-settings-error' : 'account-settings-ok') : 'account-settings'">
				<form [formGroup]="changePasswordForm" novalidate class="safe-margins"
					(ngSubmit)="changeUserPassword()">
					<div class="input-container">
						<h4>{{'USER.CHANGE_PASSWORD' | translate}}</h4>
						<input type="password" name="password"
							placeholder="&#xf084; {{ 'USER.FORM.OLD_PASSWORD' | translate }}" formControlName="password"
							autocomplete="off">
						<input type="password" name="new_password"
							placeholder="&#xf084; {{ 'USER.FORM.NEW_PASSWORD' | translate }}"
							formControlName="new_password" autocomplete="off">
						<input type="password" name="confirm_new_password"
							placeholder="&#xf084; {{ 'USER.FORM.REPEAT_PASSWORD' | translate }}"
							formControlName="confirm_new_password" autocomplete="off">
						<button type="submit"
							[disabled]="changePasswordForm.pristine || changePasswordForm.status === 'INVALID'">{{
							'USER.FORM.CHANGE_PASSWORD' | translate }}</button>
						<div [ngClass]="changePasswordErrors != null ? (changePasswordErrors.isError ? 'errors' : 'ok') : 'no-errors'"
							*ngIf="changePasswordErrors != null">
							{{ changePasswordErrors.message | translate }}
							<ul *ngIf="changePasswordErrors.errors">
								<li *ngFor="let error of changePasswordErrors.errors">
									{{ error.fieldName | translate }} : {{ error.message | translate }}
								</li>
							</ul>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- ======  User bar notifications ======= -->
		<!-- <h4 class="safe-margins">{{'USER.ACCOUNT_NOTIFICATIONS' | translate}}
			<span id="notifications-count">{{currentUser.notifications.length}}</span>
		</h4> -->
		<!-- ======  User bar notifications container ======= -->
		<!-- <div class="user-options-middle-container" [ngClass]="showPasswordForm ? 'short':''">
			<perfect-scrollbar class="user-options-notifications" (clickOutside)="showNotification = null" *ngIf="(currentUser.notifications?.length > 0)">
				<div class="notification" *ngFor="let notification of currentUser.notifications; let i = index"> -->
		<!-- ======  notification head template  ======= -->
		<!-- <div class="head safe-margins" (click)="[showNotification === i ? showNotification = null : showNotification = i, notification.state === 0 ? newNotifications = newNotifications - 1 : '', notification.state === 0 ? notification.state = 1 : '']"
					 [ngClass]="[notification.state === 0 ? 'unread' : 'readed', showNotification === i ? 'open' : '']">
						<i id="envelope" class="fa" [ngClass]="notification.state === 0 ? 'fa-envelope' : 'fa-envelope-open'"></i>
						{{notification.sender}}
						<i id="arrow-toggle" class="fa" [ngClass]="showNotification === i ? 'fa-angle-up' : 'fa-angle-down'"></i>
						<span>{{notification.timestamp | qmoment:'D MMM YYYY HH:mm'}}</span>
					</div> -->
		<!-- ======  notification body template  ======= -->
		<!-- <div class="body">
						<perfect-scrollbar class="safe-margins">
							{{notification.message}}
						</perfect-scrollbar>
						<div class="options safe-margins">
							<span class="delete">
								<i class="fa fa-trash"></i>
							</span>
						</div>
					</div>
				</div>
			</perfect-scrollbar> -->
		<!-- No new notifications -->
		<!-- <span id="no-notifications" *ngIf="!(currentUser.notifications?.length > 0)">{{'USER.ACCOUNT_NO_NOTIFICATIONS' | translate}}!</span>
		</div> -->

		<!-- ======  User bar Logout ======= -->
		<button id="logout" (click)="logout()">{{'USER.LOGOUT' | translate}}</button>

		<!-- TRADEMARK -->
		<a id="trademark" href="https://qevo.pt" target="_blank" rel="noopener">
			Powered by
			<img src="./assets/img/qevo-logo-w.svg" alt="Qevo.pt - logo">
			® {{today | date:'yyyy'}}
		</a>
	</div>

</div>
