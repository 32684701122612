/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @ Shared Components Module
* @description
* Shared Qore Components
* Created by Carlos.Moreira @ 2018/01/29
*/
// Angular Components
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Translation Modules
import { TranslateModule } from '@ngx-translate/core';

// Third Party Components
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

// Text Input Highlight
import { TextInputHighlightModule } from 'angular-text-input-highlight';

// Qore Environments
import { environment } from '../../../environments/environment.local';

// Qevo/Qore Libraries
import { QevoUtilitiesPipesModule, QevoUtilitiesDirectivesModule } from 'qevo.utilities';
import { QevoComponentsModule } from 'qevo.components';

@NgModule({
	declarations: [],
	exports: [
		QevoComponentsModule
	],
	imports: [
		TranslateModule,
		CommonModule,
		FormsModule,
		ReactiveFormsModule.withConfig({
			warnOnNgModelWithFormControl: 'always'
		}),
		QevoUtilitiesPipesModule,
		QevoComponentsModule,
		PerfectScrollbarModule,
		TextInputHighlightModule,
		QevoUtilitiesDirectivesModule,
	],
	providers: []
})
export class QoreSharedComponentsModule {
}
