/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class User Component
* @description
* Implements user functionalities like logout, change language, etc.
* Created by Carlos.Moreira @ 2018/02/01
*/
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';

// Libraries Components
import { isNullOrUndefined, QoreCustomValidators, isUndefined } from 'qevo.utilities';
import { Language, Profile, ApiError } from 'qevo.models';
import { LoggerService, AuthService } from 'qevo.services';

@Component({
	selector: 'qore-user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/
	// Component Name
	protected componentName: string;

	// Change Password Form
	changePasswordForm: FormGroup;
	changePasswordErrors: ApiError;

	availableLanguages: Language[];
	currentUser: Profile;
	newNotifications = 0;
	userInfo: string;
	showNotification: null;
	showPasswordForm: boolean;
	visibleUserOptions: boolean;

	today: number = Date.now();

	// Emitter to parent component
	@Output() showUserOptions = new EventEmitter<boolean>();

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _logger: LoggerService,
		private _fb: FormBuilder) {
		this.componentName = 'UserComponent';
	}

	ngOnInit() {
		this.createForm();

		// Fake user Information
		// this.fakeUserInfo();

		// Get User information
		this._authService.getUser()
			.pipe(filter(data => data !== null))
			.subscribe(data => {
				this.currentUser = data;

				// If nickname doesn't exist assign empty string
				this.currentUser.nickname = isNullOrUndefined(this.currentUser.nickname) ? '' : this.currentUser.nickname;

				this.userInfo = this.currentUser.full_name +
					(this.currentUser.nickname.trim() !== '' ? ' (' + this.currentUser.nickname + ')' : this.currentUser.nickname);

				// // Check new notifications
				// for (let i = 0; i < this.currentUser.notifications.length; i++) {
				// 	if (this.currentUser.notifications[i].state === 0) {
				// 		this.newNotifications += 1;
				// 	}
				// }
			});
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	*/
	/**
	 * Logouts user form the system
	*/
	logout() {
		this._logger.info(`${this.componentName}:logout`);

		this._authService.logout();

		// Redirect to login page
		this._router.navigate(['login']);
	}

	/**
	 * Changes user password
	*/
	changeUserPassword() {
		this.changePasswordErrors = null;

		this._logger.info(`${this.componentName}:changeUserPassword`, this.changePasswordForm.value);

		if (this.changePasswordForm.valid) {
			this._authService.changePassword(
				this.currentUser.id,
				this.changePasswordForm.value.password,
				this.changePasswordForm.value.new_password
			).subscribe(
				data => {
					// Everything ok ... show message ...
					this._logger.info(`${this.componentName}:changeUserPassword`, 'Ok');

					this.changePasswordErrors = {
						errors: null,
						message: 'USER.FORM.OK_CHANGING_PASSWORD',
						isError: false
					};
				},
				error => {
					// Error ... show message
					this._logger.error(`${this.componentName}:changeUserPassword`, 'Not Ok', error);

					if (isUndefined(error.message)) {
						// No ApiError object receive ... so build one with error received
						this.changePasswordErrors = {
							errors: null,
							message: isNullOrUndefined(error.error) ? 'USER.FORM.ERROR_CHANGING_PASSWORD' : 'ERRORS.' + error.error,
							isError: true
						};
					} else {
						this.changePasswordErrors = error as ApiError;
					}
				});
		}
	}

	/**
	 * Toggle user options state and emit to parent
	 * @param value
	*/
	toggleUserOptions(value: boolean) {
		this.showUserOptions.emit(value);
		this.visibleUserOptions = value;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	*/
	/**
	 * Creates the Form Group and items and respective validators
	*/
	private createForm() {
		// No errors
		this.changePasswordErrors = null;

		// Change Password Form
		this.changePasswordForm = this._fb.group(
			{
				password: ['', Validators.required],
				new_password: ['',
					// TODO: must implement the correct rules ... that must came from the database
					Validators.compose([
						Validators.required,
						Validators.pattern(QoreCustomValidators.password)
						// this is for the letters (both uppercase and lowercase) and numbers validation
					])],
				confirm_new_password: ['', Validators.required]
			}, {
			validator: QoreCustomValidators.match('new_password', 'confirm_new_password')
		});
	}
}
