/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Sidebar
* @description
* Sidebar Component
* Created by Iuri.Cunha @ 2018/02/07
*/

// Angular Components
import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

// Qore Components
import { NavigationService } from '../../../shared/services/navigation.service';
import { QoreConfig } from '../../../shared/qore.config';

// Libraries Components
import { isNullOrUndefined } from 'qevo.utilities';
import { Menu } from 'qevo.models';
import { LoggerService } from 'qevo.services';

@Component({
	selector: 'qore-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/
	// Component Name
	protected componentName: string;

	today: number;
	activeMenu = '';
	private _intervalId: any;

	menus: Menu[];

	// Version Information and Date
	versionInfo: string;
	versionDate: string;
	showVersionDate: boolean;

	// Mobile toggle class on it self (controlled by click on html)
	@HostBinding('class.mobile-mode') mobileToggle: boolean;
	@HostBinding('class.visible-user-options') visibleUserOptions: boolean;

	// User home default address
	userHomeAddress: string;

	private _firstTime: boolean;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _navigationService: NavigationService,
		private _config: QoreConfig,
		private _logger: LoggerService
	) {
		this.componentName = 'SidebarComponent';

		// Set default values
		this._firstTime = true;

		// Get active route from url (use after first loading)
		this._router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => this._router.url))
			.subscribe((event) => {
				this.activeMenu = event.split('/')[1];

				// Only run the build menu once
				if (this._firstTime) {
					// Build menu and navigate to it if we are at home :)
					this.buildMenu(this.activeMenu === '' || this.activeMenu === '/');
				}
				this._firstTime = false;
			});
	}

	ngOnInit() {
		// Clock
		this.today = Date.now();

		// Refresh timer
		this._intervalId = setInterval(() => { this.today = Date.now(); }, 5000);

		// Version Info + Date
		this.versionInfo = this._config.configurations.versionInfo.version;
		this.versionDate = this._config.configurations.versionInfo.date;
	}

	ngOnDestroy() {
		// Clear interval
		if (!isNullOrUndefined(this._intervalId)) { clearInterval(this._intervalId); }
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	*/

	/**
	 * Receives state of userOption from child component
	 * @param value
	 */
	receiveUserOptions(value: boolean) {
		this.visibleUserOptions = value;
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	*/

	/**
	 * Navigates to the clicked path
	 * Changes:
	 * 	- Now catches the promise event and indicates to the respective StoreHomeComponent | EntityHomeComponent or PartnerHomeComponent
	 *    to move to their Home component
	 * Changed by Carlos.Moreira @ 2018/08/14
	 * @param path path to navigate
	 */
	navigateTo(path: string) {
		const navigatePath: Array<any> = path.split('/');

		// If user Home includes entities text, means that it isn't a partner, so add paths to user home
		if (navigatePath.findIndex(pathToCheck => pathToCheck === 'entities') > 0) {
			navigatePath.push({ home: 1 }); // Indicate that the top area outlet is null and it's home (first time after login)
			navigatePath.push('dashboard');
		}

		this._router.navigate(navigatePath, { relativeTo: this._activatedRoute.root })
			.then(
				value => {
					this._logger.debug(`${this.componentName}:navigateTo`, `Navigated to '${navigatePath.join('/')}`,
						value);

					// Reset mobile class
					this.mobileToggle = false;
					this.visibleUserOptions = false;
				},
				error => {
					this._logger.error(`${this.componentName}:navigateTo`, `Navigated to '${navigatePath.join('/')}`,
						error);
				});
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	*/

	/**
	 * Builds the Menu and if necessary navigate to the user default home page
	 * @param navigateToHome Indicates if it should navigate to user home page
	 */
	private buildMenu(navigateToHome?: boolean) {
		// If necessary rebuild the menu
		if (isNullOrUndefined(this.menus)) {
			// Get Root Menus
			this.menus = this._navigationService.GetMenu(this._activatedRoute);
		}

		// Navigate to the new menu option
		if (navigateToHome) {
			// Get user home page
			const menu: Menu = this.menus.find((x) => x.isHome);
			const home: any = (isNullOrUndefined(menu) ? '' : menu.path);

			this._logger.debug(`${this.componentName}:buildMenu`, 'Menu', this.menus, 'Home', home);

			if (home !== '') {
				this.activeMenu = this.splitPath(home);

				// Process Home path
				const userHome: any[] = home.split('/').filter(path => path !== '');

				// If user Home includes entities text, means that it isn't a partner, so add paths to user home
				if (userHome.findIndex(path => path === 'entities') > 0) {
					userHome.push({ home: 1 }); // Indicate that the top area outlet is null and it's home (first time after login)
					userHome.push('dashboard');
				}

				// User home address
				this.userHomeAddress = '/' + userHome.join('/');

				// Navigate to user home page
				this._router.navigate(userHome, { relativeTo: this._activatedRoute.root })
					.then(
						value => {
							this._logger.debug(`${this.componentName}:buildMenu`, `Navigated to '${userHome.join('/')}`,
								value);

							// In case of false navigate to home
							if (!value) {
								this._router.navigate(['./']);
							}
						},
						error => {
							this._logger.error(`${this.componentName}:buildMenu`, `Navigated to '${userHome.join('/')}`,
								error);

							// In case of error navigate to home
							this._router.navigate(['./']);
						});
			}
		}
	}

	/**
	 * Split path to get only the first section
	 * @param path Path
	 */
	private splitPath(path: string) {
		return path.split('/')[1];
	}
}
