/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Error-404 Component
* @description
* Error page for 404 errors - page not found
* Created by Carlos.Moreira @ 2018/02/02
*/
// Angular Components
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'qore-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
	today: number;
	errorMessage: string;

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _translationService: TranslateService) {
		this.today = Date.now();
	}

	ngOnInit() {
		this._activatedRoute.params.subscribe(params => {
			this._translationService.get(params['errorMessage'] || 'ERRORS.50000ERROR-DETAIL', { errorCode: params['errorCode'] })
				.subscribe(data => { this.errorMessage = data; });
		});
	}
}
