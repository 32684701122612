/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @ Shared Module
* @description
* Shared Qore Components
* Created by Carlos.Moreira @ 2018/01/29
*/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// Translation Modules
import { TranslateModule } from '@ngx-translate/core';

// For responsive specific components
import { ResponsiveModule, ResponsiveConfig, ResponsiveState } from 'ngx-responsive';

// Cookies
import { CookieService } from 'ngx-cookie-service';

// Third Party
import { ClickOutsideModule } from 'ng-click-outside';

// Angular ng-select
import { NgSelectModule } from '@ng-select/ng-select';

// Shared Components
import { QoreConfig } from './qore.config';
import { ResponsiveDefinition } from './others/responsive-config';

// Shared Modules
import { QoreSharedComponentsModule } from './components/shared-components.module';
import { QoreSharedServicesModule } from './services/shared-services.module';

// Libraries Components Modules + Components + ...
import { QevoDsPlayerModule } from 'qevo.ds.player';
import { QevoTickerModule } from 'qevo.ticker';
import { QevoUtilitiesPipesModule, QevoUtilitiesDirectivesModule } from 'qevo.utilities';
import { LoggerService, StateService } from 'qevo.services';
import { QoreNavigationModule } from 'qore.navigation';
import { QoreComponentsModule } from 'qore.components';

@NgModule({
	declarations: [],
	exports: [
		ClickOutsideModule,
		NgSelectModule,
		QevoUtilitiesPipesModule,
		QoreSharedComponentsModule,
		QevoUtilitiesDirectivesModule,
		QevoDsPlayerModule,
		QoreNavigationModule,
		QoreComponentsModule
	],
	imports: [
		CommonModule,
		FormsModule,
		HttpClientModule,
		TranslateModule,
		ResponsiveModule.forRoot(),
		RouterModule,
		ClickOutsideModule,
		ReactiveFormsModule.withConfig({
			warnOnNgModelWithFormControl: 'always'
		}),
		NgSelectModule,
		QoreSharedComponentsModule,
		QevoUtilitiesDirectivesModule,
		QoreSharedServicesModule,
		QevoDsPlayerModule,
		QevoTickerModule,
		QevoUtilitiesPipesModule,
		QoreNavigationModule,
		QoreComponentsModule
	],
	providers: [
		QoreConfig,
		{
			provide: ResponsiveConfig,
			useFactory: ResponsiveDefinition
		},
		StateService,
		LoggerService,
		ResponsiveState,
		CookieService
	]
})
export class QoreModuleShared {
}
