<!-- MOBILE -->
<div id="mobile">
	<i class="fa fa-bars" (click)="mobileToggle = true"></i>
</div>

<!-- MAIN -->
<div id="main-container" [class.expanded]="!visibleUserOptions"
	(clickOutside)="mobileToggle = false;visibleUserOptions = false" [exclude]="'#mobile,qore-user'"
	[clickOutsideEvents]="'click,touchstart'">
	<!-- QEVO LOGO -->
	<div id="logo">
		<svg id="q" xmlns="http://www.w3.org/2000/svg" width="135.5px" height="150.297px" viewBox="0 0 135.5 150.297">
			<path fill="#FFF"
				d="M100.396 122.984c-48.231 12.855-63.395-14.188-64.561-16.433-5.711-8.299-7.385-20.588-5.07-33.818 4.112-23.5 20.071-44.241 42.013-44.241 22.188 0 30.64 20.741 26.531 44.241-4.166 23.782-19.873 44.523-42.062 44.523-1.907 0-3.622-.136-5.205-.418a47.98 47.98 0 0 0 4.771 2.187c9.32 3.651 24.728 6.248 46.833-.568 3.488-2.67 5.813-6.139 7.789-8.803 7.279-10.793 12.394-23.473 14.744-36.92 6.729-38.433-10.934-69.956-48.9-69.956-37.969 0-66.661 31.523-73.386 69.956-2.539 14.504-1.571 28.122 2.682 39.441 2.331 8.529 19.486 38.812 62.528 35.017 45.537-5.299 63.891-37.844 63.891-37.844-12.217 6.518-23.01 10.873-32.532 13.609l-.066.027z" />
			<ellipse transform="rotate(-67.299 64.825 73.357)" fill="#FFF" cx="64.819" cy="73.353" rx="39.336"
				ry="28.193" />
		</svg>
		<svg id="ore" xmlns="http://www.w3.org/2000/svg" width="339.982px" height="161.992px"
			viewBox="0 0 339.982 161.992">
			<path fill="#fff"
				d="M311.391 109.78l-5.255 27.117H279.68l5.257-27.117h26.454 0 0zM107.508 80.438c-2.727 13.156-5.005 22.476-6.833 27.947s-5.054 10.476-9.664 15.007-10.309 8.012-17.065 10.438-14.31 3.638-22.638 3.638c-7.901 0-14.762-1.146-20.578-3.436s-10.17-5.729-13.044-10.314a24.2 24.2 0 0 1-3.743-14.964c.387-5.394 1.973-14.832 4.763-28.312l4.635-22.407c2.723-13.158 5.002-22.476 6.828-27.95s5.057-10.478 9.668-15.007S50.141 7.071 56.904 4.643 71.211 1 79.538 1c7.901 0 14.76 1.147 20.578 3.438s10.168 5.732 13.044 10.313c2.845 4.448 4.161 9.702 3.747 14.966-.393 5.395-1.977 14.833-4.766 28.315l-4.633 22.406h0zM78.025 37.4c1.261-6.095 1.688-9.991 1.281-11.689s-1.795-2.548-4.169-2.548c-2.007 0-3.685.689-5.033 2.063s-2.72 5.432-4.111 12.174L53.339 98.557c-1.574 7.604-2.194 12.296-1.859 14.077s1.774 2.668 4.321 2.668c2.617 0 4.499-1.022 5.654-3.07s2.526-6.93 4.124-14.64L78.025 37.4h0zm56.17-35.7h27.571c18.383 0 30.694.64 36.938 1.92s10.89 4.548 13.924 9.806 3.383 13.645 1.043 25.166c-2.135 10.516-5.015 17.582-8.651 21.198s-9.779 5.789-18.439 6.509c7.355 1.727 12.07 4.038 14.136 6.929s3.175 5.549 3.33 7.972-.873 9.082-3.086 19.99l-7.232 35.644h-36.175l9.117-44.908c1.47-7.229 1.749-11.708.832-13.434s-4.049-2.588-9.417-2.588l-12.374 60.93H106.76L134.195 1.7h0 0zm34.258 23.12l-6.107 30.048c4.375 0 7.56-.542 9.535-1.626s3.574-4.605 4.783-10.56l1.507-7.429c.872-4.284.591-7.094-.832-8.429s-4.384-2.004-8.886-2.004h0zm62.971-23.512h64.104l-5.436 27.135h-25.659l-5.144 25.711h24.014l-5.165 25.795h-24.013l-5.967 29.816h28.214l-5.429 27.133h-66.669l27.15-135.59h0 0z" />
		</svg>
	</div>

	<!-- MOBILE BACK ICON -->
	<div id="back" (click)="mobileToggle = false">
		<i class="fa fa-chevron-left" *ngIf="mobileToggle"></i>
	</div>

	<!-- CLOCK -->
	<div id="clock">
		<span id="time" (change)="today=$event">
			{{today | qmoment:'LT'}}
		</span>
		<span id="date">
			{{today | qmoment:'ll'}}
		</span>
	</div>

	<!-- NAVIGATION MENUS -->
	<div id="nav">
		<div *ngFor="let menu of menus" class="menu" [ngClass]="activeMenu === splitPath(menu.path) ? 'active':''">
			<a (click)="navigateTo(menu.path)">
				<i class="{{menu.class}}" (click)="navigateTo(menu.path)">
					<svg *ngIf="menu.svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="512"
						height="512">
						<path [attr.d]="menu.svg" />
					</svg>
				</i>
				<p>{{ menu.name | translate}}</p>
			</a>
		</div>
	</div>

	<!-- SEARCH INPUT -->
	<!-- <div class="search">
        <i class="fa fa-search"></i>
        <input type="text" placeholder="{{'MENU.SEARCH' | translate}}...">
    </div> -->

	<!-- USER INFO SHORTCUT -->
	<qore-user (showUserOptions)="receiveUserOptions($event)"></qore-user>

	<!-- VERSION LABEL -->
	<label id="version" (mouseenter)="showVersionDate=true" (mouseleave)="showVersionDate=false"><b
			*ngIf="!showVersionDate">{{versionInfo}}</b><span *ngIf="showVersionDate">{{versionDate}}</span></label>
</div>
