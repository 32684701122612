<div class="main">
	<div>
		<img src="./assets/img/qevo-logo-w.svg" alt="Qevo - logo" class="qevo_logo">
		<div class="error">
			<h1 class="section-title">{{'ERRORS.404ERROR' | translate}}</h1>
			<p class="explanation">{{'ERRORS.404ERROR-DETAIL' | translate}}</p>
		</div>
	</div>
	<div class="footer">
		Qevo® - {{today | qmoment:'YYYY'}}
	</div>
</div>
