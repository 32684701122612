/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @ LanguageComponent
* @description
*
* Created by Carlos.Moreira @ 2018/01/30
*/
import { Component, OnInit } from '@angular/core';

// Services
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

// Third Parties
import * as moment_ from 'moment';
const moment = moment_;

// Shared Components
import { QoreConfig } from '../../../shared/qore.config';

// Libraries Components
import { Language } from 'qevo.models';
import { LoggerService, AuthService } from 'qevo.services';
import { UsersService } from 'qore.services';

@Component({
	selector: 'qore-language',
	templateUrl: './language.component.html',
	styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/
	availableLanguages: Language[];
	showLang: boolean;
	selectedLangCode: string;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		private _translateService: TranslateService,
		private _cookieService: CookieService,
		_config: QoreConfig,
		private _authService: AuthService,
		private _usersService: UsersService,
		private _logger: LoggerService) {

		// Available languages list
		this.availableLanguages = _config.configurations.availableLanguages.filter(lang => lang.isDisabled === false);

		// Selected language
		this.selectedLangCode = _translateService.currentLang;
	}

	ngOnInit() { }

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	*/
	/**
	 * Changes languages
	 * @param lang New language
	 */
	switchLang(lang: Language) {
		// Change language in translation service
		this._translateService.use(lang.langCode);

		// Change language cookie
		this._cookieService.set('QoreLang', lang.langCode,
			new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
			'/'
		);

		// Change here
		this.selectedLangCode = lang.langCode;

		// Change moment
		moment.locale(this.selectedLangCode);

		// Added by Carlos.Moreira @ 2018/03/28
		// Change user Language in Authentication Service only if logged in
		if (this._authService.isLogged) {
			// Change language for user
			this._authService.changeLanguage(lang.langCode);

			// Updates the language on the server
			this.updateLanguage(lang.langCode);
		}
	}

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	*/
	/**
	 * Indicates if the language is selected
	 * @param lang Language
	 */
	isSelected(lang: Language): boolean {
		return lang.langCode === this.selectedLangCode;
	}

	/**
	 * Updates the user language in the server
	 * @param langCode Language code
	 */
	updateLanguage(langCode: string): void {
		// Update the value on the server (API)
		this._usersService.updateLanguage(this._authService.user.id, langCode)
			.subscribe(
				result => {
					this._logger.info('LanguageComponent:updateLanguage', 'User Id', this._authService.user.id,
						'Language Code', langCode, 'Result returned', result);
				},
				error => {
					this._logger.error('LanguageComponent:updateLanguage', 'User Id', this._authService.user.id,
						'Language Code', langCode, 'Result returned', error);
				}
			);
	}
}
