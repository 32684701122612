/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @ Terms and Conditions Class
* @description
*
* Created by Carlos.Moreira @ 2022/08/18
*/
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Libraries Components
import { LoggerService } from 'qevo.services';
import { QoreConfig } from '../../../shared/qore.config';

@Component({
    selector: 'qore-terms-and-conditions',
    templateUrl: './terms-and-conditions.component.html',
    styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
    /**
     * ********************************************************************************************************************************
     * Properties
     * ********************************************************************************************************************************
    */
    // Component Name
    protected componentName: string;

    // Language Code
    curLangCode: string;

    /**
     * ********************************************************************************************************************************
     * Initialization
     * ********************************************************************************************************************************
    */
    constructor(private _qoreConfig: QoreConfig, private _activatedRoute: ActivatedRoute, private _logger: LoggerService) {
        this.componentName = 'TermsAndConditionsComponent';
    }

    ngOnInit() {
        this._activatedRoute.params.subscribe(params => {
            this.curLangCode = params['langCode'] ?? this._qoreConfig.configurations.defaultLanguage;
        });
    }
}
