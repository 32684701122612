/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @function Translation Loader factory
* @description
* To retrieve the resources files - use each of the overloads to load resources specific to each App
* TODO:
* 	- in the future implement entity saas specific resources
* Created by Carlos.Moreira @ 2018/02/
*/

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';

/**
 * Core Resources
 * @param httpClient HttpClient
 */
export function CoreHttpLoaderFactory(httpClient: HttpClient) {
	return HttpLoaderFactory(httpClient, '');
}

/**
 * Manager Resources
 * @param httpClient HttpClient
 */
export function ManagerHttpLoaderFactory(httpClient: HttpClient) {
	return HttpLoaderFactory(httpClient, 'manager');
}

/**
 * Counter Resources
 * @param httpClient HttpClient
 */
export function CounterHttpLoaderFactory(httpClient: HttpClient) {
	return HttpLoaderFactory(httpClient, 'counter');
}

/**
 * Insights Resources
 * @param httpClient HttpClient
 */
export function InsightsHttpLoaderFactory(httpClient: HttpClient) {
	return HttpLoaderFactory(httpClient, 'insigths');
}

// Config for @ngx-translate
function HttpLoaderFactory(httpClient: HttpClient, app: string | null) {
	return new TranslateHttpLoader(httpClient, 'assets/i118n/' + (app === null ? '' : app + '/'), '.json');
}

