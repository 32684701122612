/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Navigation Service
* @description
* Navigation Service - That indicates the menus to show in each stage
* Created by Carlos.Moreira @ 2018/02/13
*/

// Angular Components
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// Libraries Components
import { Menu, Roles } from 'qevo.models';
import { LoggerService, AuthService } from 'qevo.services';
import { isUndefined } from 'qevo.utilities';

@Injectable()
export class NavigationService {
	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		private _authService: AuthService,
		private _logger: LoggerService) { }

	/**
	 * ********************************************************************************************************************************
	 * Methods
	 * ********************************************************************************************************************************
	*/

	/**
	 * Obtains the Menus (Apps) which the user has permissions and indicates which one is it's home
	 * @param activatedRoute Current route
	 */
	GetMenu(activatedRoute: ActivatedRoute): Menu[] {
		const userRoles: Roles[] = this._authService.getUserProfileRoles();

		// Obtains the routes that the user as access and map them to menu options (using data object inside routes)
		const menus: Menu[] = activatedRoute.routeConfig.children.filter((route) => {
			// No data in router, so return true
			if (route.data === null || route.data.roles === null || isUndefined(route.data.roles)) {
				return true;
			} else {
				// Go throw all user roles and match them against route roles
				return userRoles.some(role => route.data.roles.some((routeRole: Roles) => routeRole === role));
			}
		}).map((route) => {
			// Marks the user Home, in case it is the Root Menu
			if (activatedRoute.routeConfig.data.isRoot) {
				const menu: Menu = route.data as Menu;
				menu.isHome = false;

				// Add the home in path
				if (route.path === 'counter' && menu.path.indexOf('/home') === -1) {
					menu.path += '/home';
				}

				userRoles.forEach(role => {
					if (route.path === 'counter' && role === Roles.Employee) {
						menu.isHome = true;
						return;

					} else if (route.path === 'insights' && (role === Roles.InsightsAdministrator)) { // || role === Roles.InsightsManager
						menu.isHome = true;
						return;

					} else if (route.path === 'manager') {
						menu.isHome = true;
						menu.path = this.getManagerHomePage(userRoles);
						return;

					} else if (route.path === 'bookings') {
						menu.isHome = role === Roles.BookingsAdministrator || role === Roles.BookingsManager ? true : false;
						menu.path = this.getBookingsHomePage(userRoles);
						return;
					}
				});
			}

			return route.data as Menu;
		});

		this._logger.debug('NavigationService:GetMenu', 'Menus', menus);
		return menus;
	}

	/**
	 * ********************************************************************************************************************************
	 * Private
	 * ********************************************************************************************************************************
	*/

	/**
	 * Gets the Manager Home page (according to it's profile)
	 */
	private getManagerHomePage(userRoles: Roles[]): string {
		let path: any;

		this._logger.debug('NavigationService:getManagerHomePage', 'Roles', userRoles);

		// Manager Home page depending on it's profile
		// Ex.
		// 	- If user is 'SU' or 'PA' 	>> Partners
		// 	- If user is 'AD' 			>> Entities
		// 	- If user is 'MN' or 'IM'
		// 		> More than one store	>> Entities
		// 		> Only one store		>> Entities
		// 	- If user is 'SP'			>> ???????? >> no access

		userRoles.forEach(role => {
			switch (role) {
				case Roles.SuperUser:
				case Roles.Partner:
					path = `/manager/partners/${this._authService.user.partner}/home`;
					return;

				case Roles.Manager:
				case Roles.Administrator:
				case Roles.InsightsManager: // Remove to rollback to insights folder only possible folder
					path = `/manager/partners/${this._authService.user.partner}/entities/${this._authService.user.entity}`;
					return;

				case Roles.Support:
					// At this time this role doesn't have access
					return;

				default:
					// No access
					return;
			}
		});

		return path;
	}

	/**
	 * Gets the Bookings Home page (according to it's profile)
	 */
	private getBookingsHomePage(userRoles: Roles[]): string {
		let path: any;

		this._logger.debug('NavigationService:getBookingsHomePage', 'Roles', userRoles);

		// Bookings Home page depending on it's profile
		// Ex.
		// 	- If user is 'BA' or 'BM'
		// 		> More than one store	>> Entities
		// 		> Only one store		>> Entities
		// 	- If user is 'SP'			>> ???????? >> no access

		userRoles.forEach(role => {
			switch (role) {
				case Roles.SuperUser:
				case Roles.Partner:
					path = `/bookings/partners/${this._authService.user.partner}`;
					return;

				case Roles.Manager:
				case Roles.Administrator:
				case Roles.BookingsAdministrator:
				case Roles.BookingsManager:
				case Roles.InsightsManager: // Remove to rollback to insights folder only possible folder
					path = `/bookings/partners/${this._authService.user.partner}/entities/${this._authService.user.entity}`;
					return;

				default:
					// No access
					return;
			}
		});

		return path;
	}
}
