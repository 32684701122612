/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Error-404 Component
* @description
* Error page for 404 errors - page not found
* Created by Carlos.Moreira @ 2018/02/02
*/
// Angular Components
import { Component } from '@angular/core';

@Component({
	selector: 'app-error-404',
	templateUrl: './error-404.component.html',
	styleUrls: ['./error-404.component.scss']
})

export class Error404Component {
	today: number;

	constructor() {
		this.today = Date.now();
	}
}
