<!-- LANG SWITCH -->
<qore-language [class.hide]="mobileMode && fullMobilePanel"></qore-language>

<!-- MAIN CONTENT -->
<div id="main">
	<!-- LOGO -->
	<div id="logo">
		<img src="./assets/img/Qore_logo.svg" alt="Qore logo">
	</div>
	<!-- CONTENT -->
	<div id="content">
		<h1>{{ 'LOGIN.CONTENT.TITLE' | translate }}</h1>
		<p>{{ 'LOGIN.CONTENT.SUB_TITLE' | translate }}</p>
		<a id="button" href="https://qevo.pt/qore" target="_blank"
			rel="noopener">{{ 'LOGIN.CONTENT.BUTTON' | translate }}
		</a>
	</div>
</div>

<!-- LOGIN PANEL -->
<div id="login-container" [class.mobile-min]="mobileMode && !fullMobilePanel"
	[class.mobile-max]="mobileMode && fullMobilePanel"
	[class.tablet-errors]="(password.errors && f.submitted) || (username.errors && f.submitted) || (error)">

	<!-- MOBILE ELEMENTS -->
	<div id="mobile-action" [class.hide]="mobileMode && fullMobilePanel">
		<button class="login-button" (click)="fullMobilePanel = true">{{ 'LOGIN.FORM.LOGIN' | translate }}</button>
	</div>
	<div id="back" [class.show]="mobileMode && fullMobilePanel" (click)="fullMobilePanel = false">
		<i class="fa fa-times-circle"></i>
	</div>

	<!-- LOADER -->
	<div class="cs-loader" *ngIf="loading">
		<p>{{ 'LOGIN.FORM.LOADING' | translate }}</p>
		<div class="cs-loader-inner">
			<label> ●</label>
			<label> ●</label>
			<label> ●</label>
			<label> ●</label>
			<label> ●</label>
			<label> ●</label>
		</div>
	</div>

	<!-- LOGIN FORM -->
	<!-- Using the "template Driven Form" approach
		- validation done by angular and not the browser "novalidate"
	-->
	<form novalidate #f="ngForm" (ngSubmit)="f.valid && login()" [hidden]="loading"
		[class.hide]="mobileMode && !fullMobilePanel"
		[class.tablet-errors]="(password.errors && f.submitted) || (username.errors && f.submitted) || (error)">
		<h2>{{ 'LOGIN.FORM.TITLE' | translate }}</h2>
		<input autofocus type="text" name="username" placeholder="&#xf007; {{ 'LOGIN.FORM.USERNAME' | translate }}"
			required ngModel #username="ngModel">
		<input type="password" name="password" placeholder="&#xf084; {{ 'LOGIN.FORM.PASSWORD' | translate }}" required
			ngModel #password="ngModel">
		<button class="login-button" type="submit">{{ 'LOGIN.FORM.LOGIN' | translate }}</button>
		<div class="form-helper" *ngIf="password.errors && f.submitted">
			<p *ngIf="password.errors.required">
				<i class="fa fa-exclamation-triangle"></i> {{ 'LOGIN.FORM.PASSWORD-REQUIRED' | translate }}</p>
		</div>
		<div class="form-helper" *ngIf="username.errors && f.submitted">
			<p *ngIf="username.errors.required">
				<i class="fa fa-exclamation-triangle"></i> {{ 'LOGIN.FORM.USERNAME-REQUIRED' | translate }}</p>
		</div>
		<!-- <span id="password-recover">
			<i class="fa fa-refresh"></i> {{ 'LOGIN.FORM.RECOVER-PASS' | translate }}</span> -->
		<div class="form-helper" *ngIf="error">
			<p *ngIf="error">
				<i class="fa fa-exclamation-triangle"></i> {{ 'LOGIN.FORM.CREDENTIALS-FAIL' | translate }}</p>
		</div>
	</form>

</div>

<!-- TRADEMARK -->
<a id="trademark" href="https://qevo.pt" target="_blank" rel="noopener">
	Powered by
	<img src="./assets/img/qevo-logo-w.svg" alt="Qevo.pt - logo">
	® {{today | date:'yyyy'}}
</a>
