/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @ Shared Services Module
* @description
* Shared Qore Services
* Created by Carlos.Moreira @ 2018/09/06
*/
// Angular Components
import { NgModule } from '@angular/core';

// Perfect Scrollbar
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';

// Shared Services
import { NavigationService } from './navigation.service';

// Qore Environments
import { environment } from '../../../environments/environment.local';

// Libraries Components
import { QoreServicesConfiguration, QoreServicesModule } from 'qore.services';
import { DEFAULT_PERFECT_SCROLLBAR_CONFIG } from 'qevo.components';

// Qevo and Qore Services Configuration Library Configurations
// NOTE:
// 	- Needs to be here because of the environment variable above that isn't recognize in a
// 	  separated file
// Added by Carlos.Moreira @ 2019/02/04
export const QevoServicesLibraryConfiguration: QoreServicesConfiguration = {
	loggerConfig: environment.configurations.logger,
	serviceConfiguration: {
		url: environment.configurations.consoleApiUrl,
		authenticationUrl: environment.configurations.authenticationUrl,
		listDefaults: environment.configurations.listDefaults
	},
	notificationConfig: {
		url: environment.configurations.notificationUrl,
		retries: {
			maxNumberOfRetries: environment.configurations.notification.maxNumberOfRetries,
			minTimeSpanLoggedToResetMaxNumberOfRetries: environment.configurations.notification.minTimeSpanLoggedToResetMaxNumberOfRetries,
			timeBetweenRetries: environment.configurations.notification.timeBetweenRetries,
			maxNumberOfRecoverRetries: environment.configurations.notification.maxNumberOfRecoverRetries,
			timeBetweenRecoverRetries: environment.configurations.notification.timeBetweenRecoverRetries,
			reloadAfterRecoverRetriesFailed: environment.configurations.notification.reloadAfterRecoverRetriesFailed,
			maxNumberOfReloadAfterFailedRecoverRetries: environment.configurations.notification.maxNumberOfReloadAfterFailedRecoverRetries
		}
	},
	authConfig: {
		url: environment.configurations.authenticationUrl,
		urlsToAddAuthenticationToken: [
			environment.configurations.consoleApiUrl,
			environment.configurations.authenticationUrl
		]
	},
	thirdParty: {
		google: {
			mapsApiKey: 'AIzaSyAbfMujiGioBtbbE9-0ETgKFoHNCpCebkE',
			fallbackMapLatitude: 38.736946,
			fallbackMapLongitude: -9.142685
		},
		microsoft: {
			atlasSubscriptionKey: '-SBBwY5Vh4f159WXeGfqgEkXfDEDoaNs1MeeM02Qsgk',
			fallbackMapLatitude: 38.736946,
			fallbackMapLongitude: -9.142685,
			// eslint-disable-next-line max-len
			timeZoneApiUrl: 'https://atlas.microsoft.com/timezone/byCoordinates/json?subscription-key={0}&api-version=1.0&options=all&query={1},{2}',
			getTimeZoneWindowsEnumApiUrl: 'https://atlas.microsoft.com/timezone/enumWindows/json?subscription-key={0}&api-version=1.0'
		}
	}
};

@NgModule({
	declarations: [
	],
	exports: [
	],
	imports: [
		QoreServicesModule.forConsoleRoot(QevoServicesLibraryConfiguration),
	],
	providers: [
		NavigationService,
		{
			provide: PERFECT_SCROLLBAR_CONFIG,
			useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
		}
	]
})
export class QoreSharedServicesModule {
}
