/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @ Login Class
* @description
*
* Created by Carlos.Moreira @ 2018/01/28
*/
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

// Libraries Components
import { Login } from 'qevo.models';
import { ResponsiveService } from 'qevo.services';
import { isNullOrUndefined } from 'qevo.utilities';
import { LoggerService, AuthService } from 'qevo.services';

@Component({
	selector: 'qore-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
	/**
	 * ********************************************************************************************************************************
	 * Properties
	 * ********************************************************************************************************************************
	*/
	// Component Name
	protected componentName: string;

	@ViewChild('f', { static: false }) form: any;
	loading = false;
	today: number = Date.now();
	error = false;

	// Responsive Service screen mode event subscription
	private _screenModeSub: Subscription;

	// Mobile mode for UI
	public mobileMode: boolean;

	// Property managed inside html
	public fullMobilePanel: boolean;

	/**
	 * ********************************************************************************************************************************
	 * Initialization
	 * ********************************************************************************************************************************
	*/
	constructor(
		private _authService: AuthService,
		private _router: Router,
		private _logger: LoggerService,
		private _responsiveService: ResponsiveService) {
		this.componentName = 'LoginComponent';
	}

	ngOnInit() {
		// Initialize Responsive Service
		this._responsiveService.initialize();

		// Detect if we are in mobile mode
		this.mobileMode = this._responsiveService.screenMode?.mobileMode;

		// Watch for screen mode event and update mobileMode flag
		this._screenModeSub = this._responsiveService.screenMode$
			.pipe(
				distinctUntilChanged()
			)
			.subscribe(screenMode => {
				// Detect if we are in mobile mode
				this.mobileMode = screenMode.mobileMode;
			});
	}

	ngOnDestroy() {
		// Unsubscribe observables
		if (!isNullOrUndefined(this._screenModeSub)) { this._screenModeSub.unsubscribe(); }

		// Destroy and clean responsive service stuff
		this._responsiveService.destroy();
	}

	/**
	 * ********************************************************************************************************************************
	 * Events
	 * ********************************************************************************************************************************
	*/
	login() {
		if (this.form.valid) {
			this.loading = true;
			this.error = false;

			const formData = this.form.value;
			const login: Login = {
				username: formData.username,
				password: formData.password
			};

			this._authService.login(login)
				.subscribe(
					data => {
						this.loading = false;

						// If user is entering App for the first time, redirect it to it's home page depending on user profile roles
						if (this._authService.redirectUrl === '') {
							const userProfile = this._authService.getUserProfile();
						}

						this._logger.debug(`${this.componentName}:login`, 'Ok so redirect to', this._authService.redirectUrl || './');

						// Set our navigation extras object
						// that passes on our global query params and fragment
						const navigationExtras: NavigationExtras = {
							queryParamsHandling: 'preserve',
							preserveFragment: true
						};

						// Redirect the user
						this._router.navigate([this._authService.redirectUrl || './'], navigationExtras)
							.then(
								value => {
									this._logger.debug(`${this.componentName}:login`,
										`Navigated to '${this._authService.redirectUrl || './'}'`, value);
									// In case of false navigate to home
									if (!value) {
										this._router.navigate(['./']);
									}
								},
								error => {
									this._logger.error(`${this.componentName}:login`,
										`Navigated to '${this._authService.redirectUrl || './'}'`, error);

									// In case of error navigate to home
									this._router.navigate(['./']);
								});
					},
					error => {
						this.loading = false;
						this.error = true;
					});
		}
	}
}
