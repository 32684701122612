/**
* @license
* Copyright Qevo - Queue Evolution. All Rights Reserved.
*/
/**
* @class Main Module
* @description
* Where Bootstrap happens
* Created by Carlos.Moreira @ 2018/01/29
*/
// Angular Components
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ModuleWithProviders } from '@angular/core';

// Qore Components
import { QoreModuleShared } from './shared/shared.module';

// Core Components
import { CoreRoutingModule } from './core/core-routing.module';
import { QoreComponent } from './qore.component';
import { QoreModuleCore } from './core/core.module';

// Libraries Components
import { AuthService } from 'qevo.services';

@NgModule({
	declarations: [
		QoreComponent
	],
	imports: [
		BrowserModule,
		QoreModuleShared,
		QoreModuleCore.forRoot(),
		CoreRoutingModule
	],
	providers: [
		AuthService
	],
	bootstrap: [QoreComponent]
})
export class QoreModule { }

